* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen home form container */
.ss-home-form-container {
  width: 91.111vw;
  height: 201.667vw;
  background-color: rgba(245,245,245,1);
  margin-top: 7.5vh;
  margin-bottom: 7.5vh;
  border: 6px solid white;
  border-radius: 0px;
  box-shadow: 0.25vh 0.25vh 0.625vh 0.625vh #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* small screen home form container container */
.ss-home-form-container-container {
  width: 64.444vw;
  height: 166.111vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* small screen home form logo container */
.ss-home-form-logo-container {
  width: 100%;
  height: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-home-form-logo-container  img {
  height: 100%;
}

/* small screen home form text & decoration 1 container */
.ss-home-form-text-decoration-1-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

/* small screen home form text */
.ss-home-form-text-container {
  font-family: AvenirNextCyr-Demi;
  font-size: 7.467vw;
  line-height: 0.8;
  color: #7A0020;
}

/* small screen home form decoration 1 container */
.ss-home-form-decoration-1-container {
  width: 13.333vw;
  height: 13.333vw;
  position: absolute;
  right: 4.444vw;
  top: -8.889vw;
}

.ss-home-form-decoration-1-container img {
  height: 100%;
}

/* small screen home form form container */
.ss-home-form-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4.444vw;
}

.ss-home-form-form-container input {
  width: 100%;
  height: 8.889vw;
  border: none;
  outline: none;
  font-family: AvenirNextCyr-Regular;
  font-size: 3.733vw;
  color: #7A0020;
  text-indent: 4.444vw;
  border-radius: 16px;
}

.ss-home-form-form-container input::placeholder {
  color: #7A0020;
}

.ss-home-form-form-container textarea {
  height: 53.333vw;
  display: flex;
  border: none;
  outline: none;
  font-family: AvenirNextCyr-Regular;
  color: #7A0020;
  font-size: 3.733vw;
  padding-top: 4.444vw;
  padding-left: 4.444vw;
  padding-right: 4.444vw;
  border-radius: 16px;
  resize: none;
}

.ss-home-form-form-container textarea::placeholder {
  color: #7A0020;
}

/* small screen home form form decoration 2 & button container */
.ss-home-form-form-decoration-2-button-container {
  width: 100%;
  height: 13.333vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* small screen home form form decoration 2 */
.ss-home-form-form-decoration-2-button-container img {
  height: 13.333vw;
}

/* small screen home form form button */
.ss-home-form-form-decoration-2-button-container button {
  width: 35.555vw;
  height: 8.889vw;
  border: none;
  border-radius: 50px;
  background-color: #7A0020;
  font-family: AvenirNextCyr-Regular;
  color: white;
  font-size: 3.733vw;
  font-weight: 500;
  letter-spacing: 0.5px;
}

@media screen and (min-width: 451px) {
  /* small screen home form container */
  .ss-home-form-container {
    display: none;
  }
}










/* large screen home form container */
.ls-home-form-container {
  width: 77.448vw;
  height: 39.635vw;
  background-color: #f8f8f8;
  border-radius: 6.667vw;
  box-shadow: 0.25vh 0.25vh 0.625vh 0.625vh #d9d9d9;
  border: 0.521vw solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

/* large screen home form container container */
.ls-home-form-container-container {
  width: 55.781vw;
  margin-left: 12.5vw;
  display: flex;
  justify-content: space-between;
}

/* large screen home form logo & text container */
.ls-home-form-logo-text-container {
}

/* large screen home form logo container */
.ls-home-form-logo-container {
  width: 10vw;
  display: flex;
}

.ls-home-form-logo-container img {
  width: 100%;
  cursor: pointer;
}

/* large screen home form text */
.ls-home-form-text {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 4vw;
  line-height: 0.9;
  padding-top: 3.333vw;
}

.ls-home-form-text span {
  color: #7A0020;
}

/* large screen home form form container */
.ls-home-form-form-container {
  display: flex;
  flex-direction: column;
  align-items: end;
}

/* large screen home form name & surname container */
.ls-home-form-name-surname-container {
  display: flex;
  flex-direction: row;
  gap: 4.127vw;
}

/* large screen home form input */
.ls-home-form-input {
  height: 3.333vw;
  border: none;
  background-color: white;
  border-radius: 1.667vw;
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 0.938vw;
  padding-left: 2.5vw;
  outline: none;
}

.ls-home-form-input::placeholder {
  width: 12.5vw;
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 0.938vw;
}

.ls-home-form-input-short {
  width: 12.5vw;
  color: #7A0020;
}

.ls-home-form-input-wide {
  width: 29.167vw;
  margin-top: 0.833vw;
}

/* large screen home form textarea */
.ls-home-form-textarea {
  width: 100%;
  height: 17.5vw;
  border-radius: 1.667vw;
  border: none;
  margin-top: 0.833vw;
  outline: none;
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 0.938vw;
  padding-left: 2.5vw;
  padding-top: 1.25vw;
  padding-right: 2.5vw;
  resize: none;
}

.ls-home-form-textarea::placeholder {
  width: 12.5vw;
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 0.938vw;
}

.ls-home-form-textarea::-webkit-scrollbar {
  display: none;
}

/* large screen home form button */
.ls-home-form-button {
  width: 11.667vw;
  height: 2.5vw;
  background-color: #7A0020;
  border-radius: 1.667vw;
  border: none;
  font-family: AvenirNextCyr-Demi;
  color: white;
  font-size: 0.938vw;
  letter-spacing: 1.5px;
  margin-top: 1.667vw;
  cursor: pointer;
}

/* large screen home form decoration 1 container */
.ls-home-form-decoration-1-container {
  width: 4vw;
  height: 100%;
  position: absolute;
  left: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.ls-home-form-decoration-1-container img {
  width: 100%;
  margin-bottom: 9.167vw;
}

/* large screen home form decoration 2 container */
.ls-home-form-decoration-2-container {
  width: 4vw;
  height: 100%;
  position: absolute;
  left: 26.614vw;
}

.ls-home-form-decoration-2-container img {
  width: 100%;
  margin-top: 3.333vw;
}

@media screen and (max-width: 450px) {
  /* small screen home form container */
  .ls-home-form-container {
    display: none;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen home doctor card container */
.ss-home-doctor-card-container {
  width: 35.555vw;
  height: 54.444vw;
  background-color:white;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top 0 right 1.111vw;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* small screen home doctor card image container */
.ss-home-doctor-card-image-container {
  width: 26.666vw;
  height: 22.222vw;
  margin-top: 4.444vw;
}

.ss-home-doctor-card-image-container img {
  width: 100%;
  height: auto;
}

/* small screen home doctor card text container */
.ss-home-doctor-card-text-container {
  width: 26.666vw;
  height: 26.667vw;
  background-color: rgba(245,245,245,1);
  z-index: +1;
  border-radius: 0px;
  position: absolute;
  bottom: 4.444vw;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.222vw;
}

.ss-home-doctor-card-text-container h3 {
  font-family: AvenirNextCyr-demi;
  color: rgba(120,0,30,1);
  font-size: 3.500vw;
  line-height: 1;
}

.ss-home-doctor-card-text-container p {
  font-family: AvenirNextCyr-Regular;
  font-size: 2.8vw;
  color: #000000;
  line-height: 1.1;
  padding-left: 4.444vw;
  padding-right: 4.444vw;
}

.ss-home-doctor-card-decoration-1-container {
  width: 4.444vw;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.ss-home-doctor-card-decoration-1-container img {
  width: 100%;
  height: auto;
}

.ss-home-doctor-card-decoration-2-container {
  width: 4.444vw;
  height: 100%;
  position: absolute;
  right: 0;
}

.ss-home-doctor-card-decoration-2-container img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 451px) {
  /* small screen home doctor card container */
  .ss-home-doctor-card-container {
    display: none;
  }
}









/* large screen home doctor card container */
.ls-home-doctor-card-container {
  width: 17.5vw;
  height: 25.833vw;
  background-color: white;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top 0 right 0vw;
  border-radius: 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* large screen home doctor card image container */
.ls-home-doctor-card-image-container {
  width: 17.5vw;
  height: 25.833vw;
}

.ls-home-doctor-card-image-container img {
  width: 100%;
}

/* large screen home doctor card text container */
.ls-home-doctor-card-text-container {
  width: 14.167vw;
  height: 11.667vw;
  background-color: rgba(250,250,250,1);
  position: absolute;
  bottom: 1.667vw;
  border-radius: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-home-doctor-card-text-container h3 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 1.458vw;
  line-height: 1;
  text-align: center;
  padding-bottom: 0.833vw;
}

.ls-home-doctor-card-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 0.938vw;
  padding-left: 2.8125vw;
  padding-right: 2.8125vw;
  text-align: center;
}

.ls-home-doctor-card-decoration-1-container {
  width: 2.5vw;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.ls-home-doctor-card-decoration-1-container img {
  width: 100%;
  margin-bottom: 1.667vw;
}

.ls-home-doctor-card-decoration-2-container {
  width: 2.5vw;
  height: 100%;
  position: absolute;
  right: 1.667vw;
}

.ls-home-doctor-card-decoration-2-container img {
  width: 100%;
  margin-top: 2.5vw;
}

@media screen and (max-width: 450px) {
  /* large screen home doctor card container */
  .ls-home-doctor-card-container {
   display: none;
  } 
}
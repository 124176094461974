* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

/* small screen home header container */
.ss-home-header-container {
  width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ss-home-header-container a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* small screen home header logo container */
.ss-home-header-logo-container {
  width: 50vw;
  height: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left:  vw;
}

.ss-home-header-logo-container img {
  height: 100%;
  max-width: 100%;
}

/* small screen home header phone button container */
.ss-home-header-phone-button-container {
  width: 17.778vw;
  height: 5vh;
  background-color: rgba(250,200,200,1);
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  margin-right: 23.333vw;
  z-index: +4;
}

.ss-home-header-phone-button-container img {
  height: 62.5%;
  max-width: 100%;
}

/* small screen home header navigation button container */
.ss-home-header-navigation-button-container {
  width: 8.889vw;
  position: absolute;
  right: 0;
  margin-right: 5.555vw;
  z-index: +4;
}

.ss-home-header-navigation-button-container img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 451px) {
  /* small screen home header container */
  .ss-home-header-container {
    display: none;
  }
}










/* large screen home header container */
.ls-home-header-container {
  width: 100%;
  height: 11.84vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large screen home header container container */
.ls-home-header-container-container {
  width: 90vw;
  height: 11.84vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* large screen home header logo container */
.ls-home-header-logo-container {
  height: 11.84vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ls-home-header-logo-container img {
  height: 4.167vw;
}

/* large screen home navigation container */
.ls-home-navigation-container {
  display: flex;
  flex-direction: row;
  gap: 4.167vw;
  align-items: center;
}

.ls-home-navigation-container a {
  text-decoration: none;
}

/* large screen home navigation list container */
.ls-home-navigation-container ul {
  display: inline-flex;
  gap: 1.667vw;
}

.ls-home-navigation-container ul li {
  list-style: none;
  font-family: AvenirNextCyr-regular;
  color: #000000;
  font-size: 1.2vw;
}

/* large screen home navigation contact button container */
.ls-home-navigation-contact-button-container {
  height: 2.5vw;
  width: 12.71vw;
  border-radius: 32px;
  background: linear-gradient(90deg, rgba(120,0,30,1) 0%, rgba(120,0,31,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-home-navigation-contact-button-container p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  font-size: 0.833vw;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 450px) {
  /* large screen home header container */
  .ls-home-header-container {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  /* large screen home header container */
  .ls-home-header-container {
    height: 7.5vw;
  }

  /* large screen home header container container */
  .ls-home-header-container-container {
    height: 7.5vw;
  }

  /* large screen home header logo container */
  .ls-home-header-logo-container {
    height: 7.5vw;
  }
}
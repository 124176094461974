* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen contact container */
.ss-contact-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen contact logo & text & map container */
.ss-contact-logo-text-map-container {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* small screen contact logo container */
.ss-contact-logo-container {
  width: 100vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5vh;
}

.ss-contact-logo-container-container {
  height: 100%;
  width: max-content;
}

.ss-contact-logo-container-container img {
  height: 100%;
}

/* small screen contact text container */
.ss-contact-text-container {
  width: 100vw;
  text-align: center;
}

.ss-contact-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: #7A0020;
  font-size: 4.800vw;
}

.ss-contact-text-container p a {
  text-decoration: none;
  color: #7A0020;
}

/* small screen contact map container */
.ss-contact-map-container {
  width: 80vw;
  height: 40vh;
  background-color: white;
  overflow: hidden;
  margin-bottom: 5vh;
}

.ss-contact-map-container iframe {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 451px) {
  /* small screen contact container */
  .ss-contact-container {
    display: none;
  } 
}









/* large screen contact container */
.ls-contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large screen contact decoration image 1 container */
.ls-contact-decoration-image-1-container {
  width: 100%;
  height: 11.84vh;
  overflow: hidden;
  display: flex;
}

.ls-contact-decoration-image-1-container img {
  height: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* large screen contact title 1 container */
.ls-contact-title-1-container {
  width: 100%;
  height: 11.84vh;
  background: linear-gradient(90deg, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 50%, rgba(122,38,49,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-contact-title-1-container h1 {
  font-family: AvenirNextCyr-Demi;
  font-size: 2.500vw;
  color: white;
  letter-spacing: 1px;
}

/* large screen contact logo & text & map container */
.ls-contact-logo-text-map-container {
  width: 100%;
  margin-top: 6.667vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10vw;
}

/* large screen contact logo & text container */
.ls-contact-logo-text-container {
  width: 30vw;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5vw;
}

.ls-contact-logo-text-container img {
  width: 20vw;
}

/* large screen contact text container */
.ls-contact-text-container p a {
  font-family: AvenirNextCyr-Regular;
  color: black;
  text-decoration: none;
}

.ls-contact-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 1.563vw;
  line-height: 1;
}

/* large screen contact map container */
.ls-contact-map-container {
  width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-contact-map-container iframe{
  width: 30vw;
  height: 30vw;
}

/* large screen contact form container */
.ls-contact-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6.667vw;
}

@media screen and (max-width: 450px) {
  /* large screen contact container */
  .ls-contact-container {
    display: none;
  } 
}

@media screen and (max-width: 1279px) {
  /* large screen contact decoration image 1 container */
  .ls-contact-decoration-image-1-container {
    height: 7.5vw;
  }

  /* large screen contact title 1 container */
  .ls-contact-title-1-container {
    height: 7.5vw;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* large screen blog header container */
.ls-blog-header-container {
  width: 100%;
  height: 11.84vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large screen blog header container container */
.ls-blog-header-container-container {
  width: 90vw;
  height: 11.84vh;
  display: flex;
  justify-content: space-between;
}

/* large screen blog header logo container */
.ls-blog-header-logo-container {
  height: 11.84vh;
  display: flex;
  flex-direction: column;
  justify-content: center;  
}

.ls-blog-header-logo-container img {
  height: 4.167vw; 
}

/* large screen blog header navigation container */
.ls-blog-header-navigation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4.167vw;
}

.ls-blog-header-navigation-container a {
  text-decoration: none;
}

.ls-blog-header-navigation-container ul {
  display: flex;
  gap: 1.667vw;
}

.ls-blog-header-navigation-container ul li {
  display: inline-flex;
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 0.833vw;
}

/* large screen blog header button container */
.ls-blog-header-button-container {
  height: 2.5vw;
  width: 15vw;
  background-image:linear-gradient(to right, rgba(120, 0, 30, 1), rgba(120, 0, 30, 1));
  border-radius: 1.667vw;
  display: flex;
}

/* large screen blog header button image container */
.ls-blog-header-button-image-container {
  width:2.5vw;
  height: 2.5vw;
}

/* large screen blog header button container container */
.ls-blog-header-button-container-container {
  width: 12.5vw;
  height: 2.5vw;
  background: linear-gradient(90deg, rgba(102,151,54,1) 0%, rgba(143,221,66,1) 100%);
  border-radius: 1.667vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ls-blog-header-button-container-container p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  letter-spacing: 0.026vw;
  font-size: 0.833vw;
}

@media screen and (max-width: 450px) {
  /* large screen blog header container */
  .ls-blog-header-container {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  /* large screen blog header container */
  .ls-blog-header-container {
    height: 7.5vh;
  }

  /* large screen blog header container container */
  .ls-blog-header-container-container {
    height: 7.5vh;
  }

  /* large screen blog header logo container */
  .ls-blog-header-logo-container {
    height: 7.5vh;
  }
}
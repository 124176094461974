* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen home navigation dropdown social media icon */
.ss-home-navigation-dropdown-social-media-icon img {
  width: 4.444vw;
  display: flex;
}
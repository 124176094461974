* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen home footer container */
.ss-home-footer-container {
  width: 100%;
}

/* small screen home footer container container */
.ss-home-footer-container-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13.333vw;
  padding-bottom: 7.5vh;
}

/* small screen home footer decoration 1 container */
.ss-home-footer-decoration-1-container {
  width: 100%;
}

.ss-home-footer-decoration-1-container img {
 width: 100%;
}

/* small screen home footer logo container */
.ss-home-footer-logo-container {
  width: 100%;
  height: 17.778vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.ss-home-footer-logo-container img {
 height: 100%;
}

/* small screen home footer social media container */
.ss-home-footer-social-media-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.222vw;
}

.ss-home-footer-social-media-container p {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 3.733vw;
}

.ss-home-footer-social-media-icon-container {
  display: flex;
  flex-direction: row;
  gap: 4.444vw;
}

/* small screen home footer text container */
.ss-home-footer-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8.889vw;
}

/* small screen home footer text container */
.ss-home-footer-text-container p {
  font-family: AvenirNextCyr-Regular;
  font-size: 2.667vw;
  color: black;
  line-height: 1.5;
}

.ss-home-footer-text-container p a {
  text-decoration: none;
  color: black;
}

/* small screen home footer copyright container */
.ss-home-footer-copyright-container {
  text-align: center;
}

.ss-home-footer-copyright-container p {
  font-family: AvenirNextCyr-Regular;
  font-size: 2.667vw;
  color: black;
}

.ss-home-footer-copyright-container p a {
  text-decoration: none;
  color: black;
}

@media screen and (min-width: 451px) {
  /* small screen home footer container */
  .ss-home-footer-container {
    display: none;
  }
}










/* large screen home footer container */
.ls-home-footer-container {
  width: 100%;
  margin-top: 6.667vw;
}

/* large screen home footer decoration 1 container */
.ls-home-footer-decoration-1-container {
  width: 100%;
  display: flex;
}

.ls-home-footer-decoration-1-container img {
  width: 100%;
}

/* large screen home footer container container */
.ls-home-footer-container-contaier {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8.333vw;
  justify-content: center;
  margin-top: 6.667vw;
}

/* large screen home footer logo container */
.ls-home-footer-logo-contaier {
  width: 11.667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ls-home-footer-logo-contaier img {
  width: 100%;
}

/* large screen home footer text 1 container */
.ls-home-footer-text-1-contaier p a {
  color: black;
}

.ls-home-footer-text-1-contaier p {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 1.146vw;
  line-height: 1.5;
}

/* large screen home footer text 2 container */
.ls-home-footer-text-2-contaier {
}

.ls-home-footer-text-2-contaier p a {
  text-decoration: none;
  color: black;
}

.ls-home-footer-text-2-contaier p {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 1.146vw;
  line-height: 1.5;
}

/* large screen home footer social media container */
.ls-home-footer-social-media-contaier {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.833vw;
}

.ls-home-footer-social-media-contaier p {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 1.146vw;
}

.ls-home-footer-social-media-icon-container {
  display: flex;
  flex-direction: row;
  gap: 1.25vw;
}

/* large screen home footer copyright container */
.ls-home-footer-copyright-container {
  width: 100%;
  margin-top: 6.667vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 4.147vw;
}

.ls-home-footer-copyright-container p a {
  text-decoration: none;
  color: black;
}

.ls-home-footer-copyright-container p {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 1.146vw;
}

@media screen and (max-width: 450px) {
  /* large screen home footer container */
  .ls-home-footer-container {
    display: none;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

/* small screen home comment box container */
.ss-home-comment-box-container {
  width: 66.667vw;
  height: 110vw;
  background-color: #f8f8f8;
  border-radius: 0px;
    flex: 0 0 auto;
  position: relative;
}

/* small screen home comment box image & name container */
.ss-home-comment-box-image-name-container {
  width: 66.667vw;
  height: 23.333vw;
  margin-top: 8.889vw;
  display: flex;
}

/* small screen home comment box image */
.ss-home-comment-box-image {
  height: 100%;
  border: 4px solid white;
  border-radius: 0px;
  margin-left: 8.889vw;
}

/* small screen home comment box name container */
.ss-home-comment-box-name-container {
  height: 23.333vw;
}

.ss-home-comment-box-name-container h3 {
  font-family: AvenirNextCyr-Regular;
  color: #7A0020;
  font-size: 4.267vw;
  margin-top: 2.222vw;
  margin-left: 4.444vw;
}

.ss-home-comment-box-name-container p {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 3.200vw;
  margin-top: 2.222vw;
  margin-left: 4.444vw;
}

/* small screen home comment box decoration 1 container */
.ss-home-comment-box-decoration-1-container {
  width: 66.667vw;
  height: 6.667vw;
}

.ss-home-comment-box-decoration-1-container img {
  height: 100%;
  margin-left: 13.333vw;
}

/* small screen home comment box decoration 2 container */
.ss-home-comment-box-decoration-2-container {
  width: 6.667vw;
  height: 38.889vw;
  position: absolute;
  top: 0;
  right: 4.444vw;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.ss-home-comment-box-decoration-2-container img {
  width: 100%;
  margin-bottom: 2.222vw;
  transform: scaleX(-1);
}

/* small screen home comment box text container */
.ss-home-comment-box-text-container {
  width: 66.667vw;
  height: 60.222vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ss-home-comment-box-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 4.2vw;
  padding-left: 8.889vw;
  padding-right: 8.889vw;
}

@media screen and (min-width: 451px) {
  /* small screen home comment box container */
  .ss-home-comment-box-container {
    display: none;
  }
}










/* large screen home comment box container */
.ls-home-comment-box-container {
  width: 25vw;
  height: 26.667vw;
  background-color: #f8f8f8;
  flex: 0 0 auto;
  border-radius: 0vw;
  border-right: 0.833vw solid rgba(250,250,250,1);
  position: relative;
}

/* large screen home comment box image & name container */
.ls-home-comment-box-image-name-container {
  height: 8.333vw;
  width: 100%;
  margin-top: 3.333vw;
  display: flex;
}

/* large screen home comment box image */
.ls-home-comment-box-image {
  height: 100%;
  margin-left: 3.333vw;
  border: 0.417vw solid white;
  border-radius: 1.875vw;
}

/* large screen home comment box name container */
.ls-home-comment-box-name-container {
  height: 100%;
}

.ls-home-comment-box-name-container h3 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 1.563vw;
  line-height: 1;
  margin-left: 0.833vw;
  margin-top: 0.833vw;
  padding-bottom: 0.833vw;
}

.ls-home-comment-box-name-container p {
  font-family: AvenirNextCyr-regular;
  color: black;
  font-size: 0.938vw;
  margin-left: 0.833vw;
}

/* large screen home comment box decoration 1 container */
.ls-home-comment-box-decoration-1-container {
  width: 100%;
  height: 2.5vw;
}

.ls-home-comment-box-decoration-1-container img {
  height: 100%;
  margin-left: 3.75vw;
}

/* small screen home comment box decoration 2 container */
.ls-home-comment-box-decoration-2-container {
  width: 2.5vw;
  height: 13.333vw;
  position: absolute;
  top: 0;
  right: 1.667vw;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.ls-home-comment-box-decoration-2-container img {
  transform: scaleX(-1);
  width: 100%;
}

/* large screen home comment box text container */
.ls-home-comment-box-text-container {
  width: 100%;
  height: 9.171vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ls-home-comment-box-text-container p {
  font-family: AvenirNextCyr-regular;
  color: black;
  font-size: 1vw;
  padding-top: 0.833vw;
  padding-left: 3.333vw;
  padding-right: 3.333vw;
}

@media screen and (max-width: 450px) {
  /* large screen home comment box container */
  .ls-home-comment-box-container {
    display: none;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

/* large screen post container */
.ls-post-container {
  
}

/* large screen post decoration image 1 container */
.ls-post-decoration-image-1-container {
  width: 100%;
  height: 11.84vh;
  overflow: hidden;
  display: flex;
}

.ls-post-decoration-image-1-container img {
  height: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* large screen post title 1 container */
.ls-post-title-1-container {
  width: 100%;
  height: 11.84vh;
  background: linear-gradient(90deg, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 50%, rgba(122,38,49,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-post-title-1-container p {
  font-family: AvenirNextCyr-Regular;
  font-size: 2.500vw;
  color: white;
  letter-spacing: 1px;
}

/* large screen post container container */
.ls-post-container-container {
  width: 100%;
  margin-top: 6.667vw;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

/* large screen post left container */
.ls-post-left-container {
  width: 65vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 8.333vw;
  display: flex;
  flex-direction: column;
  gap: 3.333vw;
}

/* large screen post left container path */
.ls-post-left-container-path {
  width: 53.333vw;
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 1.146vw;
  margin-left: 8.333vw;
}

/* large screen post left container image */
.ls-post-left-container-image {
  max-width: 53.333vw;
  margin-left: 8.333vw;
}

/* large screen post left text 1 container */
.ls-post-left-text-1-container {
  width: 53.333vw;
  margin-left: 8.33vw;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

/* large screen post left container text 1 */
.ls-post-left-container-text-1 {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 1.146vw;
}

/* large screen post left text 2 container */
.ls-post-left-text-2-container {
  display: flex;
  flex-direction: column;
  width: 53.333vw;
  margin-left: 8.333vw;
}

/* large screen post left container text 2 */
.ls-post-left-container-text-2 {
  font-family: AvenirNextCyr-Regular;
  margin-left: 2.5vw;
  font-size: 1.146vw;
  color: black;
}

/* large screen post left title container */
.ls-post-left-title-container {
  width: 53.333vw;
  min-height: 6.667vw;
  background-color: white;
  border: 0.156vw solid #7A0020;
  border-radius: 3.333vw;
  margin-left: 8.333vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ls-post-left-title-container p {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 2.188vw;
  padding-top: 0.833vw;
  padding-bottom: 0.833vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

/* large screen post left reservation container */
.ls-post-left-reservation-container {
  width: 65vw;
  height: 31.667vw;
  background: linear-gradient(90deg, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3.333vw;
}

/* large screen post left reservation image container */
.ls-post-left-reservation-image-container {
  width: 25vw;
  height: 25vw;
  background-color: white;
  margin-left: 8.333vw;
}

.ls-post-left-reservation-image-container img {
  width: 100%;
}

/* large screen post left reservation text & button container */
.ls-post-left-reservation-text-button-container {
  width: 25vw;
  height: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* large screen post left reservation text container */
.ls-post-left-reservation-text-button-container ul {
  margin-left: 1.667vw;
}

/* large screen post left reservation text */
.ls-post-left-reservation-text {
  font-family: AvenirNextCyr-Demi;
  color: white;
  font-size: 1.458vw;
  line-height: 1.8;
}

/* large screen post left reservation button */
.ls-post-left-reservation-button {
  width: 25vw;
  height: 5vw;
  background-color: #f8f8f8;
  border-radius: 3.333vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-post-left-reservation-button p {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 1.458vw;
}

/* large screen post left video container */
.ls-post-left-video-container {
  width: 53.333vw;
  margin-left: 8.333vw;
  display: flex;
  flex-direction: column;
  gap: 3.333vw;
}

/* large screen post left video title */
.ls-post-left-video-title {
  font-family: AvenirNextCyr-Regular;
  font-size: 2.188vw;
  color: black;
}

.ls-post-left-video-title span {
  font-family: AvenirNextCyr-Demi;
  color: rgba(102,151,54,1);
}

/* large screen post left infographic image */
.ls-post-left-infographic-image {
  width: 53.333vw;
  margin-left: 8.333vw;
  cursor: pointer;
}

/* large screen post left infographic background container */
.ls-post-left-infographic-background-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  position: fixed;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-post-left-infographic-large-image-container {
  display: flex;
  position: relative;
}

.ls-post-left-infographic-large-image-container img {
  max-width: 100%;
  max-height: 100%;
}

/* large screen post left infographic x container */
.ls-post-left-infographic-x-container {
  width: 2.083vw;
  height: 2.083vw;
  position: absolute;
  top: 0.781vw;
  right: 0.781vw;
  cursor: pointer;
}

/* large screen post left horizontal rule */
.ls-post-left-horizontal-rule {
  width: 53.333vw;
  margin-left: 8.333vw;
  border-top: 0.156vw solid;
  border-image: linear-gradient(to right, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 100%) 1;
}

/* large screen post left team container */
.ls-post-left-team-container {
  width: 53.333vw;
  margin-left: 8.333vw;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

/* large screen post left team row container */
.ls-post-left-team-row-container {
  display: flex;
  flex-direction: row;
  gap: 1.667vw;
}

/* large screen post left team title */
.ls-post-left-team-title {
  width: 53.333vw;
  margin-left: 8.333vw;
  text-align: center;
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 2.188vw;
}

@media screen and (max-width: 450px) {
  /* large screen post container */
  .ls-post-container {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  /* large screen post decoration image 1 container */
  .ls-post-decoration-image-1-container {
    height: 7.5vw; 
  }

  /* large screen post title 1 container */
  .ls-post-title-1-container {
    height: 7.5vw;
  }
}

@media screen and (max-width: 1439px) {
  /* large screen post left infographic large image */
  .ls-post-left-infographic-large-image-container {
    max-width: 80%;
    max-height: 80%;
  }
}

@media screen and (min-width: 1440px) 
              and (max-width: 1499px) {
  /* large screen post left infographic large image */
  .ls-post-left-infographic-large-image-container {
    max-width: 80%;
    max-height: 80%;
  }
}

@media screen and (min-width: 1500px) {
  /* large screen post left infographic large image */
  .ls-post-left-infographic-large-image-container {
    max-width: 80%;
    max-height: 80%;
  }
}










/* small screen post container */
.ss-post-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen blog decoration image 1 container */
.ss-blog-decoration-image-1-container {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ss-blog-decoration-image-1-container img {
  height: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* small screen blog title 1 container */
.ss-blog-title-1-container {
  width: 100%;
  height: 10vh;
  background: linear-gradient(90deg, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ss-blog-title-1-container p {
  font-family: AvenirNextCyr-Regular;
  font-size: 4.400vw;
  color: white;
  letter-spacing: 0px;
  padding-left: 4.444vw;
  padding-right: 4.444vw;
}

/* small screen post content container */
.ss-post-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen post content 1 container */
.ss-post-content-1-container {
  width: 100%;
  background: linear-gradient(0deg, rgba(210,210,210,1) 0%, rgba(230,230,230,1) 5%, rgba(251,251,251,1) 10%, rgba(255,255,255,1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  padding-top: 8.889vw;
  padding-bottom: 8.889vw;
}

/* small screen post content 2 container */
.ss-post-content-2-container {
  width: 100%;
  background: linear-gradient(180deg, rgba(210,210,210,1) 0%, rgba(230,230,230,1) 5%, rgba(251,251,251,1) 10%, rgba(255,255,255,1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  padding-top: 8.889vw;
}

/* small screen post content 3 container */
.ss-post-content-3-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  padding-top: 8.889vw;
}

/* small screen post content 4 container */
.ss-post-content-4-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  padding-top: 8.889vw;
}

/* small screen post content 5 container */
.ss-post-content-5-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  padding-top: 8.889vw;
}

/* small screen post content 6 container */
.ss-post-content-6-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  padding-top: 8.889vw;
}

/* small screen post content 7 container */
.ss-post-content-7-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  padding-top: 8.889vw;
}

/* small screen post content 8 container */
.ss-post-content-8-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  padding-top: 8.889vw;
}

/* small screen post container image */
.ss-post-container-image {
  width: 80vw;
}

/* small screen post title container */
.ss-post-title-container {
  width: 80vw;
  min-height: 17.778vw;
  border-radius: 17.778vw;
  border: 1px solid #7A0020;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 3.333vw;
  padding-bottom: 3.333vw;
  padding-left: 3.333vw;
  padding-right: 3.333vw;
}

.ss-post-title-container p {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 4.444vw;
}

/* small screen text 1 container */
.ss-post-text-1-container {
  width: 80vw;
  display: flex;
  flex-direction: column;
}

.ss-post-container-text-1 {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 4.5vw;
}

/* small screen text 2 container */
.ss-post-text-2-container {
  width: 73.333vw;
}

.ss-post-container-text-2 {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 4.5vw;
}

/* small screen post reservation container */
.ss-post-reservation-container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  padding-bottom: 8.889vw;
}

/* small screen post reservation image container */
.ss-post-reservation-image-container {
  width: 80vw;
  height: 80vw;
  margin-top: 8.889vw;
}

.ss-post-reservation-image-container img {
  width: 100%;
  height: 100%;
}

.ss-post-reservation-container ul {
  width: 73.333vw;
  margin-left: 6.667vw;
}

.ls-post-reservation-text {
  font-family: AvenirNextCyr-Regular;
  font-size: 4.444vw;
  color: black;
  font-weight: 500;
}

/* small screen post reservation button */
.ls-post-reservation-button {
  width: 62.222vw;
  height: 17.778vw;
  border-radius: 17.778vw;
  background: linear-gradient(90deg, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ls-post-reservation-button p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  letter-spacing: 1px;
  font-size: 4.444vw;
}

/* small screen post video container */
.ss-post-video-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
} 

.ss-post-video-title {
  width: 80vw;
  font-family: AvenirNextCyr-Demi;
  color: black;
  font-size: 4.444vw;
  padding-top: 8.889vw;
}

.ss-post-video-title span {
  color: rgba(102,151,54,1);
}

/* small screen infographic image */
.ss-post-infographic-image {
  width: 80vw;
  height: 53.333vw;
  margin-top: 8.889vw;
}

.ss-post-infographic-background-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-post-infographic-large-image-container {
  width: 90%;
  position: relative;
}

.ss-post-infographic-large-image-container img {
  width: 100%;
}

.ss-post-infographic-x-container {
  width: 8.889vw;
  height: 8.889vw;
  position: absolute;
  top: 0;
  right: 0;
}

.ss-post-infographic-x-container img {
  width: 100%;
  height: 100%;
}

/* small screen post left horizontal rule */
.ss-post-horizontal-rule {
  width: 80vw;
  height: 0.26vw;
  background: linear-gradient(90deg, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 100%);
  margin-top: 8.889vw;
}

/* small screen post team container */
.ss-post-team-container {
  width: 62.222vw;
  margin-top: 8.889vw;
  display: flex;
  flex-direction: column;
  gap: 8.889vw;
}

@media screen and (min-width: 451px) {
  /* small screen post container */
  .ss-post-container {
    display: none;
  } 
}
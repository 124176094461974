* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen home part 2 services card */
.ss-home-part-2-service-card-container {
  width: 35.555vw;
  height: 100%;
  background-image:linear-gradient(to right, rgba(122,0,32,1), rgba(122,0,31,1));
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ss-home-part-2-service-card-container p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  font-size: 3vw;
  padding-left: 4.444vw;
  padding-right: 4.444vw;
}

@media screen and (min-width: 451px) {
  /* small screen home part 2 services card */
  .ss-home-part-2-service-card-container {
    display: none;
  }
}










/* small screen home part 2 services card */
.ls-home-part-2-service-card-container {
  width: 18.833vw;
  height: 100%;
  background-image:linear-gradient(to right,  rgba(120,0,30,1), rgba(120,0,30,1));
  border-radius: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ls-home-part-2-service-card-container p {
  font-family: AvenirNextCyr-Demi;
  color: rgb(255, 255, 255);
  font-size: 1.406vw;
  padding-left: 2.222vw;
  padding-right: 2.222vw;
}

@media screen and (max-width: 450px) {
  /* small screen home part 2 services card */
  .ls-home-part-2-service-card-container {
    display: none;
  }
}
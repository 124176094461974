* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* large screen blog right marketing container */
.ls-blog-right-marketing-container {
  width: 25vw;
  display: flex;
  cursor: pointer;
}

.ls-blog-right-marketing-container img {
  width: 100%;
}

@media screen and (max-width: 450px) {
  /* large screen blog right marketing container */
  .ls-blog-right-marketing-container {
    display: none;
  }
}
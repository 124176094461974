* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen doctor profile container */
.ss-doctor-profile-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ss-doctor-profile-container a {
  text-decoration: none;
}

/* small screen doctor profile decoration image 1 container */
.ss-doctor-profile-decoration-image-1-container {
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ss-doctor-profile-decoration-image-1-container img {
  height: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* small screen doctor profile title 1 container */
.ss-doctor-profile-title-1-container {
  width: 100vw;
  height: 10vh;
  background-image:linear-gradient(to right, rgba(120, 0, 30, 1), rgba(120, 0, 30, 1));
  display: flex;
  justify-content: center;
  align-items: center;
}

.ss-doctor-profile-title-1-container p {
  font-family: AvenirNextCyr-Demi;
  font-size: 6.400vw;
  color: white;
  letter-spacing: 0.5px;
}

/* small screen doctor profile image 1 container */
.ss-doctor-profile-image-1-container {
  width: 80vw;
  height: 80vw;
  background-color: pink;
  margin: 4.444vw;
}

.ss-doctor-profile-image-1-container img {
  width: 100%;
  height: 100%;
}

/* small screen doctor profile name container */
.ss-doctor-profile-name-container {
  width: 100vw;
  text-align: center;
  margin-top: 6.667vw;
}

.ss-doctor-profile-name-container p {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 6.400vw;
  line-height: 1;
}

/* small screen doctor profile description container */
.ss-doctor-profile-description-container {
  width: 100vw;
  text-align: center;
  margin-top: 6.667vw;
}

.ss-doctor-profile-description-container p {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 4.800vw;
  line-height: 1;
  padding-left: 8.889vw;
  padding-right: 8.889vw;
}

/* small screen doctor profile service container */
.ss-doctor-profile-service-container {
  width: 88.889vw;
  height: 8.889vw;
  border-radius: 50px;
  background-image:linear-gradient(to right, rgba(120, 0, 30, 1), rgba(120, 0, 30, 1));
  margin-top: 6.667vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ss-doctor-profile-service-container p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  font-size: 3.200vw;
  line-height: 1;
  letter-spacing: 1px;
}

/* small screen doctor profile reservation container */
.ss-doctor-profile-reservation-container {
  width: 50.444vw;
  height: 12.889vw;
  border-radius: 50px;
  background: linear-gradient(90deg, rgba(250,200,200,1) 0%, rgba(250,200,200,1) 100%);
  margin-top: 2.222vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ss-doctor-profile-reservation-container p {
  font-family: AvenirNextCyr-demi;
  color: white;
  font-size: 3.200vw;
  line-height: 1;
  letter-spacing: 1.5px;
}

/* small screen doctor profile horizontal rule */
.ss-doctor-profile-horizontal-rule {
  width: 100vw;
  height: 1px;
  border: 1px solid #7A0020;
  margin-top: 6.667vw;
}

/* small screen doctor profile title 2 container */
.ss-doctor-profile-title-2-container {
  width: 100vw;
  margin-top: 6.667vw;
}

.ss-doctor-profile-title-2-container p {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  padding-left: 10vw;
  font-size: 4.800vw;
}

/* small screen doctor profile services container */
.ss-doctor-profile-services-container {
  width: 100vw;
  margin-top: 6.667vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.444vw;
}

/* small screen doctor profile service list item */
.ss-doctor-profile-services-list-item {
  width: 88.889vw;
  min-height: 8.889vw;
  background-color: rgba(240,240,240,1);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ss-doctor-profile-services-list-item p {
  font-family: AvenirNextCyr-regular;
  color: #000000;
  font-size: 2.8vw;
  padding-top: 2.222vw;
  padding-bottom: 2.222vw;
  padding-left: 5vw;
  padding-right: 5vw;
  text-align: center;
}

/* small screen doctor profile title 3 container */
.ss-doctor-profile-title-3-container {
  width: 100vw;
}

.ss-doctor-profile-title-3-container p {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  padding-left: 10vw;
  font-size: 4.800vw;
}

/* small screen doctor profile biography container */
.ss-doctor-profile-biography-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 4.444vw;
  font-size: 3.733vw;
  margin-top: 4.444vw;
}

.ss-doctor-profile-biography-container p {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  padding-left: 10vw;
  padding-right: 10vw;
}

/* small screen doctor profile title 4 container */
.ss-doctor-profile-title-4-container {
  width: 100vw;
  margin-top: 8.889vw;
}

.ss-doctor-profile-title-4-container p {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  padding-left: 10vw;
  font-size: 4.800vw;
}

/* small screen doctor profile gallery container */
.ss-doctor-profile-gallery-container {
  width: 88.889vw;
  margin-top: 6.667vw;
  display: flex;
}

.ss-doctor-profile-gallery-container tbody {
  display: flex;
  flex-direction: column;
  gap: 8.889vw;
}

.ss-doctor-profile-gallery-container tbody tr {
  width: 88.889vw;
  height: 40vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ss-doctor-profile-gallery-container tbody tr td {
  width: 40vw;
  height: 40vw;
}

.ss-doctor-profile-gallery-container tbody tr td img {
  height: 100%;
  border-radius: 16px;
}

/* small screen doctor profile phone button container */
.ss-doctor-profile-phone-button-container {
  width: 100vw;
  height: 31.111vw;
  margin-top: 8.889vw;
  margin-bottom: 8.889vw;
}

.ss-doctor-profile-phone-button-container a {
  display: flex;
  justify-content: center;
}

.ss-doctor-profile-phone-button-container a img {
  height: 31.111vw;
}

@media screen and (min-width: 451px) {
  /* small screen doctor profile container */
  .ss-doctor-profile-container {
    display: none;
  }
}










/* large screen doctor profile container */
.ls-doctor-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ls-doctor-profile-container a {
  text-decoration: none;
}

/* large screen doctor profile decoration image 1 container */
.ls-doctor-profile-decoration-image-1-container {
  width: 100%;
  height: 11.84vh;
  overflow: hidden;
  display: flex;
}

.ls-doctor-profile-decoration-image-1-container img {
  height: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* large screen doctor profile image 1 container */
.ls-doctor-profile-image-1-container {
  width: 21.667vw;
  height: 21.667vw;
  background-color: pink;
  margin-top: 3.75vw;
  box-shadow: 4px 5px 4px #d4d0cf;
}

.ls-doctor-profile-image-1-container img {
  width: 100%;
}

/* large screen doctor profile name container */
.ls-doctor-profile-name-container p {
  font-family: AvenirNextCyr-Demi;
  margin-top: 3.75vw;
  font-size: 1.979vw;
  color: #7A0020;
}

/* large screen doctor profile description container */
.ls-doctor-profile-description-container {
  width: 21.667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.917vw;
  text-align: center;
}

.ls-doctor-profile-description-container p {
  font-family: AvenirNextCyr-Regular;
  font-size: 1.250vw;
  color: black;
}

/* large screen doctor profile service container */
.ls-doctor-profile-service-container {
  width: 57.812vw;
  height: 3.75vw;
  background-image:linear-gradient(to right, rgba(120, 0, 30, 1), rgba(120, 0, 30, 1));
  border-radius: 3.333vw;
  margin-top: 8.333vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-doctor-profile-service-container p {
  font-family: AvenirNextCyr-Demi;
  color: white;
  font-size: 1.250vw;
  letter-spacing: 0.156vw;
}

/* large screen doctor profile reservation container */
.ls-doctor-profile-reservation-container {
  width: 21.667vw;
  height: 3.75vw;
  background: linear-gradient(90deg, rgba(250,200,200,1) 0%, rgba(250,200,200,1) 100%);
  border-radius: 3.333vw;
  margin-top: 2.917vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-doctor-profile-reservation-container p {
  font-family: AvenirNextCyr-Demi;
  color: white;
  font-size: 1.250vw;
  letter-spacing: 0.156vw;
}

/* large screen doctor profile horizontal rule */
.ls-doctor-profile-horizontal-rule {
  width: 87.5vw;
  border-top: 0.156vw solid #7A0020;
}

/* large screen doctor profile title 2 container */
.ls-doctor-profile-title-2-container p {
  font-family: AvenirNextCyr-Demi;
  margin-top: 6.667vw;
  font-size: 1.979vw;
  color: #7A0020;
}

/* large screen doctor profile services container */
.ls-doctor-profile-services-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5vw;
  margin-top: 10vw;
}

.ls-doctor-profile-services-list-item {
  width: 67.239vw;
  height: 5vw;
  background-color: #f8f8f8;
  border-radius: 3.333vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ls-doctor-profile-services-list-item p {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 1.458vw;
  margin-left: 7.083vw;
}

/* large screen doctor profile biography container */
.ls-doctor-profile-biography-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 3.75vw;
  margin-top: 5vw;
}

/* large screen doctor profile biography decoration 1 container */
.ls-doctor-profile-biography-decoration-1-container {
  width: 6.25vw;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: 7.083vw;
}

.ls-doctor-profile-biography-decoration-1-container img {
  width: 100%;
  transform: scaleX(-1);
  margin-bottom: 4.167vw;
}

/* large screen doctor profile biography text container */
.ls-doctor-profile-biography-text-container {
  width: 66.667vw;
}

.ls-doctor-profile-biography-text-name-container {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 1.667vw;
  margin-bottom: 5vw;
}

.ls-doctor-profile-biography-text-text-container {
  font-family: AvenirNextCyr-Regular;
  color: #7A0020;
  font-size: 1.458vw;
  margin-top: 2.5vw;
}

/* large screen doctor profile biography decoration 2 container */
.ls-doctor-profile-biography-decoration-2-container {
  width: 6.25vw;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-right: 7.083vw;
}

.ls-doctor-profile-biography-decoration-2-container img {
  width: 100%;
  margin-top: 4.167vw;

}

/* large screen doctor profile gallery container */
.ls-doctor-profile-gallery-container {
  margin-top: 6.667vw;
}

.ls-doctor-profile-gallery-container tbody {
  display: flex;
  flex-direction: column;
  gap: 2.5vw;
}

.ls-doctor-profile-gallery-container tbody tr {
  display: flex;
  flex-direction: row;
  gap: 2.5vw;
}

.ls-doctor-profile-gallery-container tbody tr td {
  width: 17.083vw;
  height: 17.083vw;
  border-radius: 2.500vw;
}

.ls-doctor-profile-gallery-container tbody tr td img {
  width: 100%;
  cursor: pointer;
  border-radius: 2.500vw;
}

@media screen and (max-width: 450px) {
  /* large screen doctor profile container */
  .ls-doctor-profile-container {
    display: none;
  } 
}

@media screen and (max-width: 1279px) {
  /* large screen doctor profile decoration image 1 container */
  .ls-doctor-profile-decoration-image-1-container {
    height: 7.5vw;
  }

  /* large screen doctor profile title 1 container */
  .ls-doctor-profile-title-1-container {
    height: 7.5vw;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Thin;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Thin.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

/* small screen services service card container */
.ss-services-service-card-container {
  display: flex;
  flex-direction: column;
 }

/* small screen services service card title container */
.ss-services-service-card-title-container {
  width: 88.889vw;
  height: 8.889vw;
  border-radius: 32px;
  margin-top: 4.444vw;
  z-index: +1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ss-services-service-card-title-container h3 {
  font-family: AvenirNextCyr-Thin;
  font-size: 3.200vw;
  margin-left: 4.444vw;
  letter-spacing: 0.5px;
}

/* small screen services service card icon container */
.ss-services-service-card-icon-container {
 width: 13.333vw;
 height: 8.889vw;
 background-color: #7A0020;
 border-radius: 32px;
 display: flex;
 justify-content: center;
 align-items: center;
}

.ss-services-service-card-icon-container img {
  width: 4.444vw;
  height: 4.444vw;
 }

/* small screen services service card content container */
.ss-services-service-card-content-container {
  width: 88.889vw;
  max-height: 88.889vw;
  border: 1px solid #7A0020;
  margin-top: -8.889vw;
  border-radius: 20px;
}

.ss-services-service-card-content-container-container {
  width: 84.445vw;
  max-height: 88.889vw;
  overflow: auto;
  padding-top: 8.889vw;
  padding-bottom: 4.444vw;
}

.ss-services-service-card-content-container-container::-webkit-scrollbar {
  width:3px; 
}

.ss-services-service-card-content-container-container::-webkit-scrollbar-track {
  margin-top: 11.111vw;
  margin-bottom: 4.444vw;
	background:#ffffff;
}

.ss-services-service-card-content-container-container::-webkit-scrollbar-thumb {
  background:#dcdcdc;
}

.ss-services-service-card-content-text1 {
  margin-top: 2.222vw;
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 4.800vw;
  margin-left: 4.444vw;
}

.ss-services-service-card-content-text2 {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 3.200vw;
  margin-top: 2.222vw;
  margin-left: 8.889vw;
  margin-right: 8.889vw;
}

.ss-services-service-card-content-text3 {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 3.200vw;
  margin-top: 4.444vw;
  margin-left: 4.444vw;
  margin-right: 8.889vw;
}

.ss-services-service-card-content-text4 {
  margin-top: 2.222vw;
}

.ss-services-service-card-content-margin-top {
  margin-top: 11.111vw;
}

@media screen and (min-width: 451px) {
  /* small screen services service card container */
  .ss-services-service-card-container {
   display: none;
  }
}










/* large screen services service card container */
.ls-services-service-card-container {
  display: flex;
  flex-direction: column;
}

/* large screen services service card title container */
.ls-services-service-card-title-container {
  width: 53.333vw;
  height: 3.333vw;
  border-radius: 2.500vw;
  margin-top: 1.667vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: +1;
}

.ls-services-service-card-title-container h3 {
  font-family: AvenirNextCyr-Regular;
  font-size: 1.146vw;
  margin-left: 3.333vw;
  letter-spacing: 0.5px;
}

/* large screen services service card icon container */
.ls-services-service-card-icon-container {
  width: 5vw;
  height: 100%;
  background-color: #7A0020;
  border-radius: 2.500vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ls-services-service-card-icon-container img {
  width: 1.667vw;
}

/* large screen services service card content container */
.ls-services-service-card-content-container {
  width: 53.333vw;
  max-height: 31.25vw;
  border: 1px solid #7A0020;
  margin-top: -3.333vw;
  border-radius: 2.5vw;
}

/* large screen services service card content container container */
.ls-services-service-card-content-container-container {
  width: 51.666vw;
  max-height: 31.25vw;
  overflow: auto;
  padding-top: 5vw;
  padding-bottom: 1.667vw;
}

.ls-services-service-card-content-container-container::-webkit-scrollbar {
  width: 0.26vw;
}

.ls-services-service-card-content-container-container::-webkit-scrollbar-track {
  margin-top: 5vw;
  margin-bottom: 1.667vw;
}

.ls-services-service-card-content-container-container::-webkit-scrollbar-thumb {
  background-color: #dadada;
}

.ls-services-service-card-content-text1 {
  font-family: AvenirNextCyr-Demi;
  margin-left: 3.333vw;
  font-size: 1.354vw;
  color: #7A0020;
}

.ls-services-service-card-content-text2 {
  font-family: AvenirNextCyr-Regular;
  margin-top: 0.833vw;
  margin-left: 4.167vw;
  margin-right: 4.167vw;
  font-size: 0.938vw;
  color: #7A0020;
}

.ls-services-service-card-content-text3 {
  font-family: AvenirNextCyr-Regular;
  font-size: 0.938vw;
  margin-left: 3.333vw;
  margin-right: 4.167vw;
  margin-top: 0.833vw;
  color: #7A0020;
}

@media screen and (max-width: 450px) {
  /* large screen services service card container */
  .ls-services-service-card-container {
    display: none;
  }
}
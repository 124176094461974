* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* large screen blog right container */
.ls-blog-right-container {
  width: 35vw;
  border-left: 0.156vw solid;
  border-image: linear-gradient(to bottom, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 50%, rgba(122,38,49,1) 100%) 1;
  padding-bottom: 8.333vw;
}

/* large screen blog right cards container */
.ls-blog-right-field-cards-container {
  width: 25vw;
  margin-left: 3.333vw;
  border-bottom: 0.260vw solid;
  border-image: linear-gradient(to right, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 100%) 1;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

/* large screen blog right small post container */
.ls-blog-right-small-posts-marketing-container {
  width: 25vw;
  background-color: white;
  margin-top: 3.333vw;
  margin-left: 3.333vw;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

/* large screen blog right horizontal rule */
.ls-blog-right-horizontal-rule {
  height: 0.26vw;
  background-image: linear-gradient(to right, rgba(120, 0, 30, 1), rgba(120, 0, 30, 1));
  border: none;
}

/* large screen blog right reservation button */
.ls-blog-right-reservation-button {
  width: 25vw;
  height: 5vw;
  background: linear-gradient(90deg, rgba(102,151,54,1) 0%, rgba(143,221,66,1) 100%);
  border-radius: 3.333vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-blog-right-reservation-button p {
  font-family: AvenirNextCyr-Demi;
  font-size: 1.667vw;
  letter-spacing: 1px;
  color: white;
}

@media screen and (max-width: 450px) {
  /* large screen blog right container */
  .ls-blog-right-container {
    display: none;
  }
}
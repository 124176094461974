* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen home part 4 container */
.ss-home-part-4-container {
  width: 100vw;
  background-image:linear-gradient(to right, rgba(245,245,245, 1), rgba(245,245,245, 1));
}

.ss-home-part-4-container-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.5vh;
  padding-top: 7.5vh;
  padding-bottom: 7.5vh;
}

/* small screen home part 4 logo container */
.ss-home-part-4-logo-container {
  width: 100%;
  height: 12.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-home-part-4-logo-container img {
  height: 100%;
}

/* small screen home part 4 text container */
.ss-home-part-4-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-home-part-4-text-container h1 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 6.400vw;
  padding-bottom: 2.5vh;
}

.ss-home-part-4-text-container p a {
  text-decoration: none;
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 3.200vw;
  line-height: 1.4; 
}

.ss-home-part-4-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 3.200vw;
  line-height: 1.4; 
}

@media screen and (min-width: 451px) {
  /* small screen home part 4 container */
  .ss-home-part-4-container {
    display: none;
  }
}










/* large screen home part 4 container */
.ls-home-part-4-container {
  width: 100%;
  margin-top: 6.667vw;
  background-image:linear-gradient(to right, rgba(249, 249, 249, 1) 0%, rgba(249, 249, 249, 1) 70%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 13.333vw;
}

/* large screen home part 4 logo container */
.ls-home-part-4-logo-container {
  width: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.ls-home-part-4-logo-container img {
  width: 100%;
  cursor: pointer;
}

/* large screen home part 4 text container */
.ls-home-part-4-text-container {
  display: flex;
  flex-direction: column;
  padding-top: 4.167vw;
  padding-bottom: 4.167vw;
}

.ls-home-part-4-text-container h1 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 2vw;
  padding-bottom: 1.667vw;
}

.ls-home-part-4-text-container p a {
  text-decoration: none;
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 1.2vw;
}

.ls-home-part-4-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 1.2vw;
  line-height: 1.4;
}

@media screen and (max-width: 450px) {
  /* large screen home part 4 container */
  .ls-home-part-4-container {
    display: none;
  }
}
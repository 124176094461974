* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen home navigation dropdown */
.ss-home-navigation-dropdown {
  width: 51.111vw;
  background-image:linear-gradient(to top, rgba(120, 0, 30, 1), rgba(120, 0, 30, 1));
  position: absolute;
  border-radius: 0 0 0 50px;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: +1;
}

.ss-home-navigation-dropdown ul {
  display: flex;
  flex-direction: column;
  gap: 1.125vh;
  margin-left: 10vw;
}

.ss-home-navigation-dropdown ul li {
  list-style: none;
  font-family: AvenirNextCyr-Regular;
  font-size: 3.733vw;
  color: white;
}

/* small screen home navigation dropdown social media container */
.ss-home-navigation-dropdown-social-media-container {
  display: flex;
  flex-direction: row;
  gap: 4.444vw;
  position: absolute;
  left: 10vw;
  bottom: 5vh;
}

@media screen and (max-width: 450px) {
  /* small screen home navigation dropdown */
  .ss-home-navigation-dropdown {
    height: 35vh;
  }
}

@media screen and (max-height: 640px) {
  /* small screen home navigation dropdown */
  .ss-home-navigation-dropdown {
    height: 40vh;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen doctors service card container */
.ss-doctors-service-card-container {
  display: flex;
  flex-direction: column;
}

/* small screen doctors service card title container */
.ss-doctors-service-card-title-container {
  width: 88.889vw;
  height: 8.889vw;
  border-radius: 32px;
  background-color: rgba(240,240,240,1);
  z-index: +1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ss-doctors-service-card-title-container h3 {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  margin-left: 4.444vw;
  letter-spacing: 0px;
}

/* small screen doctors service card icon container */
.ss-doctors-service-card-icon-container {
  width: 13.333vw;
  height: 8.889vw;
  background-color: #7A0020;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
}

.ss-doctors-service-card-icon-container img {
  width: 4.444vw;
  height: 4.444vw;
}

/* small screen doctors service card content container */
.ss-doctors-service-card-content-container {
  width: 88.889vw;
  margin-top: 4.444vw;
  display: flex;
  flex-direction: column;
  gap: 4.44vw;
}

@media screen and (min-width: 451px) {
  /* small screen doctors service card container */
  .ss-doctors-service-card-container {
    display: none;
  }
}










/* large screen doctors service card container */
.ls-doctors-service-card-container {
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

/* large screen doctors service card title container */
.ls-doctors-service-card-title-container {
  width: 38.333vw;
  height: 3.333vw;
  border: 1px solid #7A0020;
  border-radius: 1.667vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.ls-doctors-service-card-title-container h3 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 1.146vw
}

/* large screen doctors service card icon container */
.ls-doctors-service-card-icon-container {
  width: 1.667vw;
  height: 100%;
  position: absolute;
  right: 1.667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-doctors-service-card-icon-container img {
  width: 100%;
}

/* large screen doctors service card content container */
.ls-doctors-service-card-content-container {
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
  margin-bottom: 0.833vw;
}

.ls-doctors-service-card-content-row-container {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

@media screen and (max-width: 450px) {
  /* large screen doctors service card container */
  .ls-doctors-service-card-container {
    display: none;
  } 
}

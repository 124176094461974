* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

/* small screen doctors container */
.ss-doctors-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen doctors decoration image 1 container */
.ss-doctors-decoration-image-1-container {
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ss-doctors-decoration-image-1-container img {
  height: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* small screen doctors title 1 container */
.ss-doctors-title-1-container {
  width: 100vw;
  height: 10vh;
  background-image:linear-gradient(to right, rgba(120, 0, 30, 1), rgba(120, 0, 30, 1));
  display: flex;
  justify-content: center;
  align-items: center;
}

.ss-doctors-title-1-container h1 {
  font-family: AvenirNextCyr-Demi;
  font-size: 6.400vw;
  color: white;
  letter-spacing: 0.5px;
}

/* small screen doctors title 2 container */
.ss-doctors-title-2-container {
  width: 100vw;
  height: 10vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ss-doctors-title-2-container h2 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 4.800vw;
}

/* small screen doctors service cards container */
.ss-doctors-service-cards-container {
  display: flex;
  flex-direction: column;
  gap: 4.444vw;
}

/* small screen doctors phone button container */
.ss-doctors-phone-button-container {
  width: 100vw;
  height: 31.111vw;
  margin-top: 8.889vw;
  margin-bottom: 8.889vw;
}

.ss-doctors-phone-button-container a {
  display: flex;
  justify-content: center;
}

.ss-doctors-phone-button-container a img {
  height: 31.111vw;
}

@media screen and (min-width: 451px) {
  /* small screen doctors container */
  .ss-doctors-container {
    display: none;
  }
}










/* large screen doctors container */
.ls-doctors-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large screen doctors decoration image 1 container */
.ls-doctors-decoration-image-1-container {
  width: 100%;
  height: 11.84vh;
  overflow: hidden;
  display: flex;
}

.ls-doctors-decoration-image-1-container img {
  height: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* large screen doctors title 1 container */
.ls-doctors-title-1-container {
  width: 100%;
  height: 11.84vh;
  background: linear-gradient(90deg, rgba(122,38,49,1) 0%, rgba(200,81,94,1) 50%, rgba(122,38,49,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-doctors-title-1-container h1 {
  font-family: AvenirNextCyr-Demi;
  font-size: 2.500vw;
  color: white;
  letter-spacing: 1px;
}

/* large screen doctors title 2 */
.ls-doctors-title-2 {
  font-family: AvenirNextCyr-Regular;
  color: #7A0020;
  margin-top: 3.333vw;
  letter-spacing: 0.5px;
  font-size: 1.146vw;
}

/* large screen doctors service cards container */
.ls-doctors-service-cards-container {
  display: flex;
  flex-direction: column;
  gap: 0.833vw;
  margin-top: 3.333vw;
}

/* large screen doctors phone button container */
.ls-doctors-phone-button-container {
  width: 100%;
  height: 25vw;
  margin-top: 6.667vw;
}

.ls-doctors-phone-button-container a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ls-doctors-phone-button-container a img {
  height: 100%;
}

@media screen and (max-width: 450px) {
  /* large screen doctors container */
  .ls-doctors-container {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  /* large screen doctors decoration image 1 container */
  .ls-doctors-decoration-image-1-container {
    height: 7.5vw;
  }

  /* large screen doctors title 1 container */
  .ls-doctors-title-1-container {
    height: 7.5vw;
  }
}
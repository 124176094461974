* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

/* small screen services container */
.ss-services-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen services decoration image 1 container */
.ss-services-decoration-image-1-container {
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ss-services-decoration-image-1-container img {
  height: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* small screen services title 1 container */
.ss-services-title-1-container {
  width: 100vw;
  height: 10vh;
  background-image:linear-gradient(to right, rgba(120, 0, 30, 1), rgba(120, 0, 30, 1));
  display: flex;
  justify-content: center;
  align-items: center;
}

.ss-services-title-1-container h1 {
  font-family: AvenirNextCyr-Demi;
  font-size: 6.400vw;
  color: white;
  letter-spacing: 0.5px;
}

/* small screen services text 1 container */
.ss-services-text-1-container {
  width: 88.889vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ss-services-text-1-text-container {
  width: 53.335vw;
  display: flex;
  flex-direction: column;
  gap: 8.889vw;
  padding-top: 8.889vw;
}

.ss-services-text-1-text-container h2 {
  font-family: AvenirNextCyr-Demi;
  color: #7a2631;
  font-size: 5.800vw;
  line-height: 1;
  text-align: center;
}

.ss-services-text-1-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 3.733vw;
}

/* small screen services text 1 decoration 1 container */
.ss-services-text-1-decoration-container {
  width: 13.333vw;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.ss-services-text-1-decoration-container img {
  width: 100%;
  margin-top: 8.888vw; 
}

.ss-services-text-1-decoration-2-container {
  justify-content: end;
}

/* small screen services title 2 container */
.ss-services-title-2-container {
  width: 100vw;
  text-align: center;
  margin-top: 8.888vw;
}

.ss-services-title-2-container h2 {
  font-family: AvenirNextCyr-Demi;
  color: #7a2631;
  font-size: 5.867vw;
}

/* small screen services call button container */
.ss-services-phone-button-container {
  width: 100vw;
  height: 31.111vw;
  margin-top: 8.889vw;
  margin-bottom: 8.889vw;
}

.ss-services-phone-button-container a {
  display: flex;
  justify-content: center;
}

.ss-services-phone-button-container a img {
  height: 31.111vw;
}

@media screen and (min-width: 451px) {
  /* small screen services container */
  .ss-services-container {
    display: none;
  }
}










/* large screen services container */
.ls-services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large screen services decoration image 1 container */
.ls-services-decoration-image-1-container {
  width: 100%;
  height: 11.84vh;
  overflow: hidden;
  display: flex;
}

.ls-services-decoration-image-1-container img {
  height: 100%;
  min-width: 100%;
  flex-shrink: 0;
}

/* large screen services title 1 container */
.ls-services-title-1-container {
  width: 100%;
  height: 11.84vh;
  background: linear-gradient(90deg, rgba(120,0,30,1) 0%, rgba(120,0,30,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-services-title-1-container h1 {
  font-family: AvenirNextCyr-Demi;
  font-size: 2.500vw;
  color: white;
  letter-spacing: 1px;
}

/* large screen services title 2 container */
.ls-services-title-2-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3.333vw;
}

.ls-services-title-2-container h2 {
  font-family: AvenirNextCyr-Demi;
  font-size: 1.667vw;
  color: #7a2631;
}

/* large screen services phone button container */
.ls-services-phone-button-container {
  width: 100%;
  height: 25vw;
  margin-top: 6.667vw;
}

.ls-services-phone-button-container a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ls-services-phone-button-container a img {
  height: 100%;
}

@media  screen and (max-width: 450px) {
  /* large screen services container */
  .ls-services-container {
    display: none;
  } 
}

@media screen and (max-width: 1279px) {
  /* large screen services decoration image 1 container */
  .ls-services-decoration-image-1-container {
    height: 7.5vw;
  }

  /* large screen services title 1 container */
  .ls-services-title-1-container {
    height: 7.5vw;
  }
}
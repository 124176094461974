* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen home part 6 container */
.ss-home-part-6-container {
  width: 100vw;
  background-color: rgba(240,240,240,1);
}

/* small screen home part 6 container container */
.ss-home-part-6-container-container {
  width: 100vw;
  padding-top: 7.5vh;
  padding-bottom: 7.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.5vh;
}

/* small screen home part 6 title container */
.ss-home-part-6-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-home-part-6-title-container h1 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 6.400vw; 
}

/* small screen home part 6 doctors container */
.ss-home-part-6-doctors-container {
  width: 100vw;
}

.ss-home-part-6-doctors-table {
  width: 100vw;
}

.ss-home-part-6-doctors-table tbody tr {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8.889vw;
}

.ss-home-part-6-doctors-table tbody tr + tr  {
  border-top: 8.889vw solid transparent;
}

/* small screen home part 6 button */
.ss-home-part-6-button {
  width: 48.889vw;
  height: 5vh;
  background-color: #7A0020;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-home-part-6-button p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  font-size: 3.200vw;
}

@media screen and (min-width: 451px) {
  /* small screen home part 6 container */
  .ss-home-part-6-container {
    display: none;
  }
}










/* large screen home part 6 container */
.ls-home-part-6-container {
  width: 100%;
  height: 74.167vw;
  background-color: #f8f8f8;
  margin-top: 6.667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* large screen home part 6 container container */
.ls-home-part-6-container-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vw;
}

/* large screen home part 6 doctors container */
.ls-home-part-6-doctors-container tbody{
  display: flex;
  flex-direction: column;
  gap: 4.167vw;
}

.ls-home-part-6-doctors-container tbody tr {
  display: flex;
  flex-direction: row;
  gap: 4.167vw;
}

/* large screen home part 6 button */
.ls-home-part-6-button {
  width: 18vw;
  height: 3.333vw;
  background-color: #7A0020;
  border-radius: 1.667vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ls-home-part-6-button p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  font-size: 1vw;
}

@media screen and (max-width: 450px) {
  /* large screen home part 6 container */
  .ls-home-part-6-container {
    display: none;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

/* small screen home part 5 container */
.ss-home-part-5-container {
}

/* small screen home part 5 container container */
.ss-home-part-5-container-container {
  padding-top: 7.5vh;
  padding-bottom: 7.5vh;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

/* small screen home part 5 title container */
.ss-home-part-5-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ss-home-part-5-title-container h1 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 6.400vw;
}

/* small screen home part 5 comments container */
.ss-home-part-5-comments-container {
  height: 115.55vw;
  margin-left: 5.555vw;
  margin-right: 5.555vw;
  display: flex;
  gap: 8.889vw;
  overflow-x: auto;
}

@media screen and (min-width: 451px) {
  /* small screen home part 5 container */
  .ss-home-part-5-container {
    display: none;
  }
}










/* large screen home part 5 container */
.ls-home-part-5-container {
  width: 100%;
  margin-top: 6.667vw;
  display: flex;
  flex-direction: row;
}

/* large screen home part 5 title container */
.ls-home-part-5-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 6.667vw;
  margin-right: 6.667vw;
}

.ls-home-part-5-title-container h1 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 2.4vw;
  text-align: right;
  line-height: 1;
}

/* large screen home part 5 comments container */
.ls-home-part-5-comments-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 3.333vw;
}

.ls-home-part-5-comments-container::-webkit-scrollbar-thumb {
  background-color:rgba(250,250,250,1);
}

@media screen and (max-width: 1279px) {
  /* large screen home part 5 comments container */
  .ls-home-part-5-comments-container::-webkit-scrollbar {
    height: 3px;
  }
}

@media screen and (min-width: 1280px) {
  /* large screen home part 5 comments container */
  .ls-home-part-5-comments-container::-webkit-scrollbar {
    height: 10px;
  }
}

@media screen and (max-width: 450px) {
  /* large screen home part 5 container */
  .ls-home-part-5-container {
    display: none;
  }
}
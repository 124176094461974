* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #7A0020; 
}

/* small screen home part 7 container */
.ss-home-part-7-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 451px) {
  /* small screen home part 7 container */
  .ss-home-part-7-container {
   display: none;
  }
}










/* large screen home part 7 container */
.ls-home-part-7-container {
  margin-top: 6.667vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 450px) {
  /* large screen home part 7 container */
  .ls-home-part-7-container {
    display: none;
  }
}
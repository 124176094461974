* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Bold;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.ttf);
}

/* small screen home part 1 container */
.ss-home-part-1-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* small screen home part 1 image & textbox container */
.ss-home-part-1-image-textbox-container {
  width: 100vw;
  height: 62.5vh;
  display: flex;
  flex-direction: row;
}

/* small screen home part 1 image 1 container*/
.ss-home-part-1-img-1-container {
  width: 100vw;
  height: 62.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ss-home-part-1-img-1-container img {
  height: 100%;
}

/* small screen home part 1 textbox 1 container */
.ss-home-part-1-textbox-1-container {
  width: 53.333vw;
  height: 62.5vh;
  background-image:linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 3.125vh;
  position: absolute;
  right: 10vw;
}

.ss-home-part-1-textbox-1-container a {
  text-decoration: none;
}

/* small screen home part 1 textbox 1 text container */
.ss-home-part-1-textbox-1-text-container {
  width: 44.444vw;
}

.ss-home-part-1-textbox-1-text-container h3 {
  font-family: AvenirNextCyr-bold;
  font-size: 5vw;
  line-height: 1;
  color: #7A0020;
  text-align: center;
}

.ss-home-part-1-textbox-1-text-container p {
  font-family: AvenirNextCyr-regular;
  font-size: 2.933vw;
  color: black;
  line-height: 1.2;
  padding-top: 2.5vh;
}

/* small screen home part 1 textbox 1 button */
.ss-home-part-1-textbox-1-button {
  width: 35.555vw;
  height: 5vh;
  background-color: rgba(120,0,30,1);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* small screen home part 1 textbox 1 button */
.ss-home-part-1-textbox-1-button p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  font-size: 3.200vw;
  letter-spacing: 0.5px;
}

/* small screen home part 1 textbox 2 container */
.ss-home-part-1-textbox-2-container {
  width: 100vw;
  height: 27.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* small screen home part 1 textbox 2 container container */
.ss-home-part-1-textbox-2-container-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  gap: 2.222vw;
}

/* small screen home part 1 textbox 2 decoration 1 container */
.ss-home-part-1-textbox-2-decoration-1-container {
  width: 13.333vw;
  display: flex;
}

.ss-home-part-1-textbox-2-decoration-1-container img {
  width: 100%;
}

/* small screen home part 1 textbox 2 text container */
.ss-home-part-1-textbox-2-text-container {
  width: 57.778vw;
  text-align: center;
}

.ss-home-part-1-textbox-2-text-container a {
  text-decoration: none;
  color: #000000;
}

.ss-home-part-1-textbox-2-text-container h1 {
  font-family: AvenirNextCyr-Demi;
  color: rgba(120,0,30,1);
  font-size: 5.867vw;
  margin-bottom: 1.25vh;
}

.ss-home-part-1-textbox-2-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 3.200vw;
  display: inline-block;
  text-align: left;
  line-height: 1.3;
}

/* small screen home part 1 textbox 2 decoration 2 container */
.ss-home-part-1-textbox-2-decoration-2-container {
  width: 13.333vw;
  display: flex;
}

.ss-home-part-1-textbox-2-decoration-2-container img {
  width: 100%;
}

@media screen and (min-width: 451px) {
  /* small screen home part 1 container */
  .ss-home-part-1-container {
    display: none;
  }
}










/* large screen home part 1 container */
.ls-home-part-1-contaner {
  width: 100%;
}

/* large screen home part 1 image & textbox container */
.ls-home-part-1-image-textbox-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* large screen home part 1 textbox container */
.ls-home-part-1-textbox-container {
  width: 30vw;
  height: 100%;
  background-image:linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  position: absolute;
  top: 0;
  right: 11vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.ls-home-part-1-textbox-container a {
  text-decoration: none;
}

/* large screen home part 1 textbox text container */
.ls-home-part-1-textbox-text-container {
  width: 22.5vw;
  margin-bottom: 2.5vw;
}

.ls-home-part-1-textbox-text-container h3 {
  font-family: AvenirNextCyr-Bold;
  color: #7A0020;
  text-align: center;
  font-size: 2.188vw;
  line-height: 1;
  margin-bottom: 2.5vw;
}

.ls-home-part-1-textbox-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 1.250vw;
  line-height: 1.2;
}

/* large screen home part 1 textbox button */
.ls-home-part-1-textbox-button {
  width: 13.333vw;
  height: 2.5vw;
  background: linear-gradient(90deg, rgba(250,200,200,1) 0%, rgba(250,200,200,1) 70%);
  border-radius: 32px;
  margin-bottom: 6.25vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ls-home-part-1-textbox-button p {
  font-family: AvenirNextCyr-Regular;
  color: rgb(255, 255, 255);
  font-size: 1vw;
  letter-spacing: 0px;
}

@media screen and (max-width: 450px) {
  /* large screen home part 1 container */
  .ls-home-part-1-contaner {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  /* large screen home part 1 image */
  .ls-home-part-1-image-textbox-container img {
    width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  /* large screen home part 1 container */
  .ls-home-part-1-contaner {
    height: 100vh;
  }
  /* large screen home part 1 image & textbox container */
  .ls-home-part-1-image-textbox-container {
    height: 88.16vh;
  }
  /* large screen home part 1 image */
  .ls-home-part-1-image-textbox-container img {
    min-width: 100%;
    height: 100%;
  }
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen arrow up icon container */
.ss-arrow-up-icon-container {
  width: 11.111vw;
  height: 11.111vw;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  right: 5vw;
  bottom: 5vw;
  position: fixed;
  z-index: +2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-arrow-up-icon-container img {
  height: 8.333vw;
}

@media screen and (min-width: 451px) {
  /* small screen arrow up icon container */
  .ss-arrow-up-icon-container {
    display: none;
  }
}










/* large screen arrow up icon container */
.ls-arrow-up-icon-container {
  width: 3.333vw;
  height: 3.333vw;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  right: 5vw;
  bottom: 5vw;
  position: fixed;
  z-index: +2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ls-arrow-up-icon-container img {
  height: 2.5vw;
}

@media screen and (max-width: 450px) {
  .ls-arrow-up-icon-container {
    display: none;
  }
} 
/* small screen blog post container */
.ss-blog-post-container {
  width: 81.819vw;
  height: 45.454vw;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4.545vw;
}

/* small screen blog post image */
.ss-blog-post-container img {
  width: 36.364vw;
  height: 36.364vw;
}

/* small screen blog post text container */
.ss-blog-post-text-container {
  width: 31.818vw;
  height: 36.364vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* small screen blog post title */
.ss-blog-post-text-title {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 3.889vw;
  line-height: 1.1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* small screen blog post paragraph */
.ss-blog-post-text-paragraph {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 2.778vw;
  line-height: 1.1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

@media screen and (min-width: 450px) {
  /* small screen blog post container */
  .ss-blog-post-container {
    display: none;
  }
}
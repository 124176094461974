* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen home part 3 container */
.ss-home-part-3-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* small screen home part 3 container container */
.ss-home-part-3-container-container {
  width: 75.555vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 7.5vh;
  padding-bottom: 7.5vh;
}

/* small screen home part 3 decoration 1 container */
.ss-home-part-3-decoration-1-container {
  width: 7.778vw;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-self: stretch;
}

.ss-home-part-3-decoration-1-container img {
  transform: scaleX(-1);
  width: 100%;
}

/* small screen home part 3 text container */
.ss-home-part-3-text-container {
  width: 51.11vw;
  height: 100%;
}

.ss-home-part-3-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 4vw;
  line-height: 1.2;
}

/* small screen home part 3 decoration 2 container */
.ss-home-part-3-decoration-2-container {
  width: 7.778vw;
  height: 100%;
  display: flex;
}

.ss-home-part-3-decoration-2-container img {
  width: 100%;
}

@media screen and (min-width: 451px) {
  /* small screen home part 3 container */
  .ss-home-part-3-container {
    display: none;
  }
}










/* large screen home part 3 container */
.ls-home-part-3-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6.667vw;
}

/* large screen home part 3 container container */
.ls-home-part-3-container-container {
  width: 85vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4.167vw;
}

/* large screen home part 3 decoration 1 container */
.ls-home-part-3-decoration-1-container {
  width: 4.167vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}

.ls-home-part-3-decoration-1-container img {
  transform: scaleX(-1);
  width: 100%;
}

/* large screen home part 3 text container */
.ls-home-part-3-text-container {
  width: 59.998vw;
  height: 100%;
}

.ls-home-part-3-text-container p {
  font-family: AvenirNextCyr-Regular;
  color: #000000;
  font-size: 1.6vw;
  line-height: 1.2;
}

/* large screen home part 3 decoration 2 container */
.ls-home-part-3-decoration-2-container {
  width: 4.167vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}

.ls-home-part-3-decoration-2-container img {
  width: 100%;
}

@media screen and (max-width: 450px) {
  /* large screen home part 3 container */
  .ls-home-part-3-container {
    display: none;
  }
}
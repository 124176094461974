* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

/* small screen home part 2 container */
.ss-home-part-2-container {
  width: 100vw;
  height: 62.5vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8.889vw;
}

/* small screen home part 2 title */
.ss-home-part-2-title {
  font-family: AvenirNextCyr-Demi;
  color: #7a2631;
  font-size: 6.400vw;
}

/* small screen home part 2 row 1 container */
.ss-home-part-2-row-1-container {
  width: 100vw;
  height: 12.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8.889vw;
}

/* small screen home part 2 row 2 container */
.ss-home-part-2-row-2-container {
  width: 100vw;
  height: 12.5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8.889vw;
}

/* small screen home part 2 button */
.ss-home-part-2-button {
  width: 40vw;
  height: 5vh;
  background-color: RGBA(250,200,200,1);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ss-home-part-2-button p {
  font-family: AvenirNextCyr-REGULAR;
  color: WHITE;
  font-size: 3vw;
}

@media screen and (min-width: 451px) {
  /* small screen home part 2 container */
  .ss-home-part-2-container {
    display: none;
  }
}










/* large screen home part 2 container */
.large-home-part-2-container {
  width: 100%;
  height: 33.333vw;
  background-color: #f8f8f8;
  margin-top: 6.667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5.833vw;
}

/* large screen home part 2 title */
.ls-home-part-2-title {
  font-family: AvenirNextCyr-Demi;
  color: #7a2631;
  font-size: 2vw;
}

/* large screen home part 2 cards container */
.ls-home-part-2-cards-container {
  width: 100%;
  height: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.667vw;
}

/* large screen home part 2 text */
.ls-home-part-2-text {
  font-family: AvenirNextCyr-Demi;
  color: black;
  font-size: 1vw;
  text-decoration: underline;
}

@media screen and (max-width: 450px) {
  /* large screen home part 2 container */
  .large-home-part-2-container {
    display: none;
  }
}
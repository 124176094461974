* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ss-doctor-profile-slideshow-background-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: +3;
}

/* small screen doctor profile slideshow container */
.ss-doctor-profile-slideshow-container {
  margin-top: 5vw;
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  position: absolute;
  top: 50%;
  margin-top: -50%;
  position: fixed;
}

.ss-doctor-profile-slideshow-container-hiddne-image {
  max-width: 90vw;
  border: 3px solid black;
  border-radius: 16px;
  visibility: hidden;
}

.ss-doctor-profile-slideshow-arrow {
  width: 5vw;
  height: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ss-doctor-profile-slideshow-arrow img {
  width: 100%;
}

.ss-doctor-profile-slideshow-left-arrow {
  left: 0px;
}

.ss-doctor-profile-slideshow-right-arrow {
  right: 0px;
}

.ss-doctor-profile-slideshow-x-icon-container {
  width: 7.5vw;
  height: 7.5vw;
  position: absolute;
  right: 0px;
}

@media screen and (min-width: 451px) {
  .ss-doctor-profile-slideshow-background-container {
    display: none;
  }
}









.ls-doctor-profile-slideshow-background-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
}

/* large screen doctor profile slideshow container */
.ls-doctor-profile-slideshow-container {
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
}

.ls-doctor-profile-slideshow-container-hiddne-image {
  border: 3px solid black;
  border-radius: 16px;
  visibility: hidden;
}

.ls-doctor-profile-slideshow-arrow {
  width: 3.571%;
  height: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ls-doctor-profile-slideshow-arrow img {
 width: 100%;
 cursor: pointer; 
}

.ls-doctor-profile-slideshow-left-arrow {
  left: 0px;
  cursor: pointer;
}

.ls-doctor-profile-slideshow-right-arrow {
  right: 0px;
  cursor: pointer;
}

.ls-doctor-profile-slideshow-x-icon-container {
  width: 5.714%;
  position: absolute;
  cursor: pointer;
  right: 0px;
}

@media screen and (max-width: 450px) {
  .ls-doctor-profile-slideshow-background-container {
    display: none;
  }
}

@media screen and (max-width: 1439px) {
  .ls-doctor-profile-slideshow-container-hiddne-image {
    max-width: 600px;
    max-height: 600px;
  }
}

@media screen and (min-width: 1440px) {
  .ls-doctor-profile-slideshow-container-hiddne-image {
    max-width: 800px;
    max-height: 800px;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: AvenirNextCyr-Regular;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf);
}

@font-face {
  font-family: AvenirNextCyr-Demi;
  src: url(../../../assets/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf);
}

/* small screen doctors doctor card container */
.ss-doctors-doctor-card-container {
  width: 88.889vw;
  height: 44.444vw;
  background-color: rgba(240,240,240,1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4.444vw;}

.ss-doctors-doctor-card-container a {
  text-decoration: none;
}

/* small screen doctors doctor card image container */
.ss-doctors-doctor-card-image-container {
  width: 36.667vw;
  height: 36.667vw;
}

.ss-doctors-doctor-card-image-container img {
  width: 100%;
}

/* small screen doctors doctor card text & button container */
.ss-doctors-doctor-card-text-button-container {
  width: 38.89vw;
  height: 36.667vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* small screen doctors doctor text 1 */
.ss-doctors-doctor-card-text-1 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 4vw;
}

/* small screen doctors doctor text 2 */
.ss-doctors-doctor-card-text-2 {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 3.200vw;
  margin-top: -2.222vw;
}

/* small screen doctors doctor button */
.ss-doctors-doctor-card-button {
  width: 100%;
  height: 8.889vw;
  border-radius: 32px;
  background-image:linear-gradient(to right, rgba(250, 200, 200, 1), rgba(250, 200, 200, 1));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-doctors-doctor-card-button p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  font-size: 2.667vw;
  letter-spacing: 0.5px;
}

@media screen and (min-width: 451px) {
  /* small screen doctors doctor card container */
  .ss-doctors-doctor-card-container {
   display: none;
  }
}










/* large screen doctors doctor card container */
.ls-doctors-doctor-card-container {
  width: 18.333vw;
  height: 8.333vw;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* large screen doctors doctor card image container */
.ls-doctors-doctor-card-image-container {
  width: 6.667vw;
  height: 6.667vw;
  margin-left: 0.833vw;
}

.ls-doctors-doctor-card-image-container img {
  width: 100%;
}

/* large screen doctors doctor text & button container */
.ls-doctors-doctor-card-text-button-container {
  height: 6.667vw;
  margin-left: 1.667vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ls-doctors-doctor-card-text-button-container a {
  text-decoration: none;
}

/* large screen doctors doctor text 1 */
.ls-doctors-doctor-card-text-1 {
  font-family: AvenirNextCyr-Demi;
  color: #7A0020;
  font-size: 0.833vw;
  line-height: 1;
}

/* large screen doctors doctor text 2 */
.ls-doctors-doctor-card-text-2 {
  font-family: AvenirNextCyr-Regular;
  color: black;
  font-size: 0.625vw;
  padding-right: 1.667vw;
}

/* large screen doctors doctor button */
.ls-doctors-doctor-card-button {
  width: 7.5vw;
  height: 1.667vw;
  background-color: #7A0020;
  border-radius: 1.667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-doctors-doctor-card-button p {
  font-family: AvenirNextCyr-Regular;
  color: white;
  font-size: 0.625vw;
}

@media screen and (max-width: 450px) {
  /* large screen doctors doctor card container */
  .ls-doctors-doctor-card-container {
    display: none;
  }  
}